//
// Modal
//

.modal-rounded {
  border-radius: $modal-content-border-radius !important;
}

.commigModal-title {
  margin-top: 24px;
  width: 100%;
  text-align: center;
}

.commigModal-description {
  width: 100%;
  text-align: center;
}

.commingModal-header {
  padding: 0;
  border: none;
  margin-right: 16px;
  margin-top: 16px;
}

.commigModal-footer {
  padding-top: 0;
  border: none;
}